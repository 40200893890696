@use '@angular/material' as mat;
@import '~material-design-icons/iconfont/material-icons.css';
@import '../../../node_modules/@angular/material/theming';
@include mat.core();

$app-theme-color: mat.define-light-theme($app-primary-color, $app-accent-color, $app-warn-color);

@include mat.all-component-themes($app-theme-color);

/* sidebar */
.mat-drawer-content {
  flex-grow: 1;
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}

.mat-drawer-container {
  background: unset;
}

/* Select - DropDown - MultSelect*/

.mat-select {
  background: white;
  width: 100%;
  height: 30px !important;
  box-sizing: border-box;
  outline: 1px solid rgba(black, 0.2) !important;
}

.mat-select.input-error {
  border-style: solid;
  border-width: 0 0 0 2px;
  border-color: $base-color-error;
}


.mat-select-trigger,
.mat-select-value-text {
  height: 100% !important;
}

.mat-select.mat-select-disabled {
  opacity: 0.8;
  background: $color-locked;
}

.mat-select-value,
.mat-select-content {
  color: black;
}

.mat-select-value {
  padding: 0.5em 0.6em 0.5em 0.5em;
  vertical-align: middle;
}

/* Dialog */

.cdk-overlay-container {
}

.header-container-material {
  position: relative;
  height: 10%;
  background: mat.get-color-from-palette($app-primary-color);
  color: white;
  font-size: 1em;
  box-sizing: border-box;
  padding: 0.2em 1em 0.2em 1em;
}

.full-width-dialog .mat-dialog-container {
  max-width: 100vw !important;
}

@media screen and (max-width: 600px) {
  .mat-dialog-content {
    padding: 1em !important;
    max-width: 600px !important;
  }

  .mat-dialog-container {
    padding: 1em !important;
    max-width: 600px !important;
  }
}



/* Button */

.mat-button-base {
  border-color: white;
}

.mat-raised-button.mat-accent {
  color: white;
}

.mat-mini-fab.mat-accent {
  color: white;
  background: mat.get-color-from-palette($app-accent-color, 900);
}

.mat-mini-fab:disabled.mat-warn {
  //background: mat-color($app-warn-color, 50);
}

.mat-icon-button {
  border: 1px solid rgba(black, 0.1) !important;
  font-size: 1.2em;
}

.table-cancel,
.table-modifica,
.table-modifica {
  .mat-icon-button {
    width: 20px !important;
    height: 20px!important;
    line-height: 10px !important;
  }
}

.button-add {
  .mat-raised-button {
    line-height: 30px;
  }
}

.header-applica,
.dettaglio-action {
  .mat-flat-button {
    line-height: inherit !important;
  }
}


/* Paginator */

.mat-paginator {
}

.mat-paginator-container {
  justify-content: center !important;
  min-height: unset !important;
  height: 50px;
}

.mat-paginator-range-actions {
  margin-left: -100px;
  align-items: center !important;
  justify-content: center !important;
  .mat-icon-button {
  }
  .mat-icon-button:last-of-type {
  }
}

.mat-paginator-range-label {
}



/* Snackbar */

.snackbar-close .mat-icon-button {
  color: rgba(black, 0.8) !important;
  font-weight: bold;
  font-size: 20px;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: none;
}

.snackbar-alert {
  background: rgba(yellow, 0.8) !important;
  color: #2a2a2a !important;
}


/* Table */
th {

}

td {
  vertical-align: center;
}

tr.mat-header-row {
  height: 30px !important;
}

.table-container {
  tr:nth-child(2n) {
    background: $color-sfondo-riga;
  }

  tr:nth-child(2n).legenda1 {
    background: $color-legenda1;
  }
}

.highlight {
  background: rgba($color-primary, 0.1) !important;
  outline: 1px solid rgba($color-primary, 0.5);
}

.legenda1 {
  background: $color-legenda1;
}


.mat-row {
  height: 20px !important;
  //background: whitesmoke !important;
}

.mat-table thead {
}

.mat-header-cell {
  position: relative;
  font-weight: 500;
  font-size: 1em;
  color: black;
  padding: 0 0.5em 0 0 !important;
}

.mat-cell {
  position: relative;
  box-sizing: border-box;
  border: 1px solid rgba(black, 0.1);
  padding: 0.2em 0.5em 0.2em 0.3em !important;
  height: 100%;
  vertical-align: middle!important;
}

.box-content .mat-cell {
  border: none;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
}

/* Paginator */

.mat-paginator-range-label {
  //display: none;
}

/* Input */


/* Radio */
.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgba(black, 0.5);
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  border-color: rgba(black, 0.7);
  background: rgba(black, 0.7);
}

// SnackBar -------------

.snackbar-ok {
  background: #6FBF8A;
  color: #ffffff;
  text-shadow: black;
  padding: 0.5em;
}

.snackbar-info {
  background: $base-color-info;
  color: white;
  text-shadow: black;
}

.snackbar-alert {
  background: $base-color-attenzione;
  color: white;
  text-shadow: black;
}

.snackbar-error {
  background: $base-color-error;
  color: white;
  text-shadow: black;
}

.snackbar-ok,
.snackbar-info,
.snackbar-alert,
.snackbar-error {

  .mat-button-base {
    background: none;
    padding: 0;
    color: white;
  }
}

/* Expansion Panel*/

.mat-expansion-panel-header.mat-expanded {
  height: 2em !important;
}

.mat-expansion-panel-header {
  padding: 0 0 0 7px !important;
}

.mat-expansion-panel-header-title {
}

.mat-expansion-panel-body  {
  box-sizing: border-box;
  padding: 0.5em !important;
}

.mat-expansion-panel-spacing {
  //margin: 0 !important;
  margin: 0.5em 0 0.5em 0 !important;
}

.mat-panel-title {

}

/* Tabs */

.mat-tab-body-content {
  background: white;
  padding: 0.2em;
  border: solid rgba(black, 0.2);
  border-width: 0 1px 1px 1px;
}

.tab-intervento .mat-tab-label-container {
  display: none !important;
}

.gallery .mat-tab-body-content {
  height: 620px;
}

/* Autocomplete */

.autocomplete-container.mat-focused .mat-form-field-outline .mat-form-field-outline-start,
.autocomplete-container.mat-focused .mat-form-field-outline .mat-form-field-outline-gap,
.autocomplete-container.mat-focused .mat-form-field-outline .mat-form-field-outline-end
{
  border-bottom: none;
  border-radius: 0;
}

/* CheckBox*/

.checkbox-blue {
  .mat-checkbox-frame {
    border-color: $color-campi-open;
    border-width: 1px;
  }
}

/* List */

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  height: 2em !important;
  padding: 0 !important;
}

.mat-list-base .mat-list-item:nth-of-type(2n) {
  background: $color-sfondo-riga;
}

.mat-list-base .mat-list-item:active,
.mat-list-base .mat-list-item:focus {
  background: rgba($color-primary, 0.3);
}

.riga-selected .mat-list-base .mat-list-item {
   //background: rgba($color-sfondo-chiusura-stanze-home, 0.3);
}

.mat-list-text {
  padding: 0 !important;
}

/* Card */

.card-spesa {
  .mat-card {
    padding: 0.5em 16px 16px 16px;
  }

  .mat-card-header-text {
    margin: 0 !important;
  }

  .mat-card-subtitle {
    margin: 0;
  }
}

.mat-card-subtitle,
.mat-expansion-panel-header-title {
  color: black !important;
  font-weight: 600;
}

//Tree
.node-item {
  cursor: pointer;
  font-size: 1em;
}

.node-item-selected {
  color: red;
  cursor: pointer;
  font-size: 1em;
}
