@use'@angular/material' as mat;
@import '../../../node_modules/@angular/material/theming';
@import "palette";



//Material Theme
$app-primary-color: mat.define-palette($md-mcgpalette0, 800);
$app-accent-color: mat.define-palette($md-mcgpalette2, 700);
$app-warn-color: mat.define-palette(mat.$red-palette);

//Font
$font-base-family: "roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
$font-base-color: black;
$font-base-size: 0.9em;

$font-title-size: 1.5em;

//colors
$color-accent: mat.get-color-from-palette($app-accent-color, 700);
$color-accent-light: mat.get-color-from-palette($app-accent-color, 400);
$color-primary: mat.get-color-from-palette($app-primary-color, 800);
$color-primary-light: mat.get-color-from-palette($app-primary-color, 400);
$color-warn: mat.get-color-from-palette(mat.$red-palette, 600);
$color-gray: #eaeaea;
$color-locked: #dbdbdb;
$color-campi-open: mat.get-color-from-palette($app-primary-color, 500);

$color-sfondo-riga: #e7e7e7;

$color-sfondo-base: $color-primary;
$color-text-logo: #898989;

$color-sfondo-menu: $color-primary;
$color-border-menu: mat.get-color-from-palette($app-primary-color, 900);
$color-text-menu: #616161;

$color-header-table: mat.get-color-from-palette($app-primary-color, 700);
$color-footer-table: mat.get-color-from-palette($app-primary-color, 700);

$color-legenda1: rgba(black, 0.2);

$base-color-ok: $color-primary;
$base-color-attenzione: mat.get-color-from-palette($app-accent-color);
$base-color-error: mat.get-color-from-palette($app-warn-color);
$base-color-info: $color-primary;

$error-form: mat.get-color-from-palette($app-warn-color, 700);

$base-color-riga-verde: rgb(11, 166, 8);
$base-color-riga-gialla: rgba(255, 253, 19, 1 );
$base-color-riga-arancione: rgba(255, 115, 11, 1);
$base-color-riga-rossa: rgba(255, 0, 0, 1 );
$base-color-riga-blu: rgb(21, 81, 165);
$base-color-riga-viola: rgb(143, 32, 255);

//Sizes
$min-width: 320px;

$menu-width: 15%;
$menu-max-width: 250px;
$menu-min-width: 150px;

$header-height: 40px;
